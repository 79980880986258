import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loader from './loader.gif';
import PrivateRoute from './components/login/PrivateRoute';
import AdminRoute from './components/login/AdminRoute';
import './App.css';

const Login = lazy(() => import('./components/login/Login'));
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));
const Categories = lazy(() => import('./components/categories/Categories'));
const Products = lazy(() => import('./components/products/Products'));
const Approvisionnement = lazy(() =>
  import('./components/approvisionnement/Approvisionnement')
);
const Inventaire = lazy(() => import('./components/inventaire/Inventaire'));
const VoirLesProduits = lazy(() =>
  import('./components/voirLesProduits/VoirLesProduits')
);
const MyProducts = lazy(() => import('./components/myProducts/MyProducts'));
const ProductSold = lazy(() => import('./components/productSold/ProductSold'));
const UsersProducts = lazy(() =>
  import('./components/usersProducts/UsersProducts')
);
const NewProducts = lazy(() => import('./components/newProducts/NewProducts'));
const Users = lazy(() => import('./components/users/Users'));
const Setting = lazy(() => import('./components/setting/Setting'));
const NoMatch = lazy(() => import('./components/noMatch/NoMatch'));
const Annuler = lazy(() => import('./components/annuler/Annuler'));

function App() {
  return (
    <Suspense
      fallback={
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img alt='Loader' src={Loader} />
        </div>
      }
    >
      <Router>
        <Switch>
          <PrivateRoute path='/' exact component={Dashboard} />
          <PrivateRoute path='/categories' exact component={Categories} />
          <PrivateRoute path='/products' exact component={Products} />
          <AdminRoute path='/rapport-vente' exact component={ProductSold} />
          <PrivateRoute path='/my-products' exact component={MyProducts} />
          <PrivateRoute path='/annuler' exact component={Annuler} />
          <PrivateRoute
            path='/approvisionnements'
            exact
            component={Approvisionnement}
          />
          <AdminRoute path='/users-products' exact component={UsersProducts} />
          <PrivateRoute path='/new-products' exact component={NewProducts} />
          <PrivateRoute path='/inventaire' exact component={Inventaire} />
          <PrivateRoute
            path='/produits-par-utilisateur'
            exact
            component={VoirLesProduits}
          />
          <AdminRoute path='/users' exact component={Users} />
          <PrivateRoute path='/setting' exact component={Setting} />
          <Route path='/login' exact component={Login} />
          <Route path='*' exact component={NoMatch} />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
